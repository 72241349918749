.bookmarkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  aspect-ratio: 1;
}

.bookmarkButton[data-active='true'] {
  background-color: red;
}
