@import '../../index.scss';

.ChipButton {
  @include p3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: max-content;
  background-color: white;
  border-radius: 10px;
  text-wrap: nowrap;
  height: 20px;
  border: 1px solid transparent;
  cursor: pointer;
}

.ChipButton[data-selected='true'] {
  border-color: $primary100;
}
