@import '../../../../index.scss';

.title {
    font-family: $fontFamilyMariupol;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: $darkest100;
    @media screen and (max-width: 340px){
        font-size: 25px;
    }
}
