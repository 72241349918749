@import '../../../../index.scss';

.pageWrap {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  height: 100dvh;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 0 25px;
  flex-grow: 1;

  .title {
    font-family: $fontFamilyMariupol;
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: $darkest100;
    text-align: center;
  }

  .description {
    font-family: $fontFamilyInter;
    font-weight: 500;
    font-size: 16px;
    line-height: 23.2px;
    color: $darkest60;
    text-align: center;
  }

  .wrap {
    position: relative;
    width: 125px;
    aspect-ratio: 1;
    border-radius: 50%;
  }

  .loader {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
  }

  .centerImage {
    position: absolute;
    width: 90%;
    height: 90%;
    object-fit: cover;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid $primary100;
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
}
