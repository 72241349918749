@import '../../../../index';

.Speciality {
  @include p3;
  flex: auto 0 1;
  display: flex;
  padding: 8px 14px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: white;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: max-content;
  text-wrap: nowrap;
  cursor: pointer;

  .Icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-family: calibri;
  }
  .Code {
    color: $darkest40;
    font-size: 10px;
  }
}

.Speciality[data-selected='true'] {
  border-color: $primary100;
}
