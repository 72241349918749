@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import '../../font/include.css';
@import '../../index.scss';

.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 100vw;
  width: 100vw;
  max-width: 100vw;

  min-height: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;

  background-color: #F9F4EF;
  
  h1 {
    @include h1;
  }
  h2 {
    @include h2;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }
}