@import '../../../../index.scss';

.inputWrap {
  $inputWrap: &;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  border: 1px solid $primary20;
  gap: 8px;
  border-radius: 12px;

  .input {
    border: none;
    height: 100%;
    flex: 1;
    font-size: 16px;
    font-family: $fontFamilyInter;
    padding: 0;
    margin: 0;
    height: 22px;
  }

  .input:focus {
    outline: none;
  }

  .iconWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    aspect-ratio: 1;
  }
  .iconWrap > * {
    height: 100%;
    aspect-ratio: 1;
  }
  .iconWrap > svg > path {
    stroke: $primary40;
    stroke-width: 2;
  }
}
