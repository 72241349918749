@import '../../../../index.scss';

.wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;

  gap: 24px;
  margin-bottom: 34px;
}
