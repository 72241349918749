@import '../../../../index.scss';

.titleWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .speciality {
    font-weight: 500;
    font-size: 16px;
  }
}
