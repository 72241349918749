@import '../../../../index.scss';

.savedOffersWrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  padding: 24px 24px;
  margin-bottom: 10px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  @include h2;
  margin-top: 24px;
}
