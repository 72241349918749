@import './../../../../index.scss';

.code-chip {
  font-family: $fontFamilyInter;
  background-color: $primary40;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $darkest100;
}

.code-chip[data-size='small'] {
  height: 14px;
  width: max-content;
  border-radius: 4px;
  padding-right: 2px;
  padding-left: 2px;
  padding-top: 1px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.code-chip[data-size='big'] {
  width: max-content;
  height: 20px;
  border-radius: 6px;
  padding: 0px 2px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  vertical-align: middle;
}
