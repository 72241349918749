@import '../../../../index.scss';

.chip {
    background-color: $primary100;
    font-family: $fontFamilyInter;
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: space-between;
    padding: 7px 12px;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }

    .title{
        color: #fff;
        margin-left: 8px;
    }
}