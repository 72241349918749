@import '../../../../index';

.SpecialitiesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
  .SpecialitiesTitle {
    @include h3;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .MoreButton {
      color: $primary100;
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;
    }
  }

  .SpecialitiesList {
    gap: 8px;
    overflow-x: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;

    .SpecialitiesRow {
      display: flex;
      gap: 8px;
    }
  }
}
