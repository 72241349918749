@import '../../index.scss';

.AppInput {
  min-height: 65px;
  background-color: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 10px;
  width: 99%;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  position: relative;
  > .Placeholder {
    font-family: $fontFamilyInter;
    font-weight: 400;
    font-size: 14px;
    color: $darkest40;

    transition: all 0.05s ease-in-out;
    position: absolute;
    left: 20px;
    z-index: 100500;
    pointer-events: none;
  }
  &.filled {
    > .Placeholder {
      top: 7px;
      font-size: 10px;
      font-weight: 400;
      color: $darkest60;
    }
    &.error {
      > .Placeholder {
        color: #ab1317;
      }
      > input {
        border: 1px solid #b90e11;
        padding-left: 18px;
        background-color: #fce0e0;
        color: #b90e11;
      }
      > .AppInputClear {
        filter: brightness(0.7);
      }
    }
  }
  > input {
    outline: none;
    border: none;
    background: none;
    padding: 0;
    border-radius: 12px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    font-family: $fontFamilyInter;
    font-weight: 500;
    font-size: 16px;

    transition: 0.4s;
    padding-left: 20px;
    min-height: 65px;
  }
  &.filled {
    > input {
      transform: translateY(0);
    }
  }
  > .AppInputClear {
    cursor: pointer;

    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 16px;
    transition: .4s;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
