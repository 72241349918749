.AppRipples {
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  > .ripple {
    position: absolute;
    transition-property: opacity, top, left, width, height, background-color;
    transition-timing-function: ease-in-out;
    border-radius: 50%;
    background-color: rgba(#FFFFFF, 0.24);
    pointer-events: none;
  }
}