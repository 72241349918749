.AppContentAnimated {
  position: fixed;
  top: 57px;
  left: 0;
  right: 0;
  bottom: 0;

  > .AppContentAnimated_Block {
    display: flex;
    flex-direction: column;

    opacity: 0;
    user-select: none;
    animation: .5s forwards AppContentAnimated_Block_FadeIn ease-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 5;
    transform-origin: top right;
  }

}

@keyframes AppContentAnimated_Block_FadeIn {
  0% {
    opacity: 0;
    transform: translateX(128px);
  }
  100% {
    transition: 1s;
    opacity: 1;
    transform: translateX(0);
    user-select: auto;
  }
}