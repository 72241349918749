@import '../../../index';

.MyRatingStart {
  background-color: #F9F4EF;
  min-height: 100vh;
  > div {
    padding: 64px 25px 22px 25px;

    .AppButtonLink {
      flex: 0 0 auto;

      border: none;
      outline: none;
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: $primary100;
      padding: 20px;
      box-sizing: border-box;

      color: white;
      font-family: $fontFamilyInter;
      font-weight: 700;
      font-size: 16px;
      text-align: center;

      border-radius: 12px;

      cursor: pointer;
      user-select: none;

      transition:
        opacity 0.1s ease-in-out,
        transform 0.2s cubic-bezier(0.32, 0, 0.15, 1.75);
      &.disabled {
        opacity: .5;
      }

      &:hover {
        transform: scale(0.99);
      }
      &:active {
        transform: scale(0.95);
      }
    }
    .AppButtonLink {
      text-decoration: none;
      &.disabled {
        pointer-events: none;
      }
    }

    > .AppButton,
    > .AppButtonLink {
      display: block;
      margin-top: 24px;
    }
  }

  background-image: url(./avramenko.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 50vh;
}
