@import '../../../index';

.MyRatingLoading {
    height: 100%;
    background-color: #F9F4EF;
    > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      > .MyRatingLoadingSpinner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        > span {
          position: absolute;
          top: -7px;
          display: block;
          width: 112px;
          height: 55px;
          overflow: hidden;
          > span {
            display: block;
            width: 112px;
            height: 110px;
            box-sizing: border-box;
            border-top-left-radius: 110px;
            border-top-right-radius: 110px;
            border: 4px solid $primary100;
            border-bottom: none;
          }
          transform-origin: center bottom;
          animation: rotating 2s infinite;
        }

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 300px;

          & h3 {
            font-family: $fontFamilyMariupol;
            font-size: 30px;
            color: $darkest100;
            text-align: center;
            margin-top: 20px;
          }

          & p {
            font-family: $fontFamilyInter;
            font-size: 16px;
            margin-top: 15px;
            text-align: center;
            color: $darkest60;
          }
        }
      }
    }
  }

  @keyframes rotating {
      0% { transform: rotate(0deg); }
     50% { transform: rotate(180deg); }
    100% { transform: rotate(360deg); }
  }

.MyRatingResult {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    > .MyRatingResult_Cards {
        flex: 1;
        .slick-list {
            box-sizing: border-box;
            height: 100%;
            max-width: 760px;
            margin: 0 auto;
            .slick-track {
                height: 100%;
                box-sizing: border-box;
                // transition: .7s !important;
                .slick-slide {
                    height: 100%;
                    box-sizing: border-box;
                    > div {
                        height: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        > .slick-dots {
            bottom: 0;

            & li {
                width: 10px;
            }

            & li button::before {
                color: $primary40;
                opacity: 1;
                font-size: 7px;
            }

            & .slick-active button::before {
                color: $primary100;
            }
        }
    }
    > .MyRatingResult_Bottom {
        // padding: 0 0px 25px 0px;
        flex: 0 0 auto;
        & .LinkAgain {
            color: $primary100;
            font-family: $fontFamilyInter;
            font-weight: bold;
            display: flex;
            padding: 15px;
            align-items: center;
            transition: .5s;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            > .LinkAgainEmoji {
                margin-right: 5px;
                &.click {
                    animation: rotation .8s infinite linear;
                    transform-origin: center center;
                }

                @keyframes rotation {
                    from {
                      transform: rotate(359deg)
                    }
                    to {
                      transform: rotate(0deg);
                    }
                }
            }

            &:hover,
            &:focus {
                transform: scale(0.95);
            }
        }
    }
}
.slick-slide {

    &:nth-child(1) .MyRatingResultCard { background-color: $secondaryPurple100; }
    &:nth-child(2) .MyRatingResultCard { background-color: $primary100; }
    &:nth-child(3) .MyRatingResultCard { background-color: $secondaryPink100; }
    &:nth-child(4) .MyRatingResultCard { background-color: $secondaryPurple100; }
    &:nth-child(5) .MyRatingResultCard { background-color: $primary100; }
    &:nth-child(6) .MyRatingResultCard { background-color: $secondaryPink100; }
    &:nth-child(7) .MyRatingResultCard { background-color: $secondaryPurple100; }
    &:nth-child(8) .MyRatingResultCard { background-color: $primary100; }
    &:nth-child(9) .MyRatingResultCard { background-color: $secondaryPink100; }

    > div {
        padding: 10px 0 25px 20px;

    }

    &:first-child > div {
        padding-left: 0;
    }

    @media (max-width: 768px) {
        &:first-child > div {
            padding: 10px 0 25px 20px;
        }
        &:last-child > div {
            padding: 10px 25px 25px 20px;
        }
    }

    height: 100%;
    box-sizing: border-box;
    outline: none;

    .MyRatingResultCard {
        // flex: 1 0 auto;
        height: 100%;

        border-radius: 30px;
        // width: 70vw;
        max-height: 600px;
        box-sizing: border-box;
        padding: 30px 26px;
        display: flex;
        flex-direction: column;

        > .MyRatingResultCard_Top {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > img {
                opacity: 0.45;
            }
            > span.MyRatingResultCard_Num {
                @include p3;
                color: $white;
                opacity: .6;
            }
        }

        > .MyRatingResultCard_Content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding-bottom: 5px;
            > div {
                display: flex;
                align-items: center;
                margin: 10px 0;
                > img {
                    height: auto;
                    max-width: 35px;
                }
            }

            > h2 {
                @include h2;
                color: $white;
                line-height: 100%;
                margin-bottom: 10px;
                max-width: 430px;

                @media (max-width: 768px) {
                    font-size: 25px;
                }
            }
            > p {
                @include p3;
                font-weight: bold;
                color: $white;
                opacity: .7;
            }
        }

        > .MyRatingResultCard_Chart {
            flex: .6;
        }

        &.LastCard{
            border: 2px dashed #F59D33;
            background-color: $primary15;
            > .MyRatingResultCard_Content {
                > h2 {
                    color: $darkest100;
                    max-width: unset;
                    text-align: center;
                    margin: 0 auto;
                }

                > .AppButton {
                    margin-top: 20px;

                    > img {
                        padding: 2px;
                        max-width: 15px;
                        margin-right: 5px;
                        background-color: white;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}

.AppContent {
    &.DonateCard {
        overflow: unset;
        flex: 0 auto;
        @media (max-height: 600px) {
            display: none;
        }
    }
    .MyRatingDonateCard {
        background-color: $primary15;
        margin-top: 10px;
        border: 2px dashed #F59D33;
        padding: 10px 20px;
        max-width: 800px;
        border-radius: 20px;
        > .MyRatingDonateCard_Content {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: 640px) {
                flex-direction: unset;
                justify-content: space-between;
            }

            >.MyRatingDonateCard_Header {
                display: flex;
                flex-direction: column;
                align-items: center;

                @media (min-width: 640px) {
                    align-items: flex-start;
                }
                > p {
                    @include p3;
                    color: $darkest40;
                    max-width: 100%;
                    margin-top: 0;
                    color: #e17b00;

                    text-align: center;
                    opacity: .7;

                    > span {
                        text-decoration: line-through;
                    }

                    > img {
                        width: 15px;
                        height: auto;
                    }

                    @media (min-width: 640px) {
                        text-align: left;
                        max-width: 420px;
                    }
                }
            }
            > a {
                margin-top: 10px;
                flex: 0 0 auto;
                @media (min-width: 640px) {
                    max-width: 30%;
                    margin-top: 0;
                }
            }
        }
    }
}

.MyRatingResultCardChart {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    > canvas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}
