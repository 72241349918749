@import '../../../../index';

.MyRatingHeader {
  background-color: #FDF0E0;
  flex: 0 0 auto;

  display: block;
  width: 100%;
  box-sizing: border-box;

  min-height: 0;
  overflow: auto;

  > div {
    max-width: 800px;
    margin: 0 auto;

    padding: 40px 25px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    @media (max-width: 560px) {
      padding: 25px;
    }

    > .MyRatingHeaderTop {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      > .MyRatingHeaderSteps {
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        text-decoration: none;
        @include p3;
        font-weight: bold;
        color: $primary100;
        display: flex;
        flex-direction: row;
        > img {
          margin-right: 10px;
        }
        align-items: center;

        padding: 8px;
        margin: -8px 0 0 -8px;
        border-radius: 4px;
        user-select: none;
        transition: background-color 0.1s ease-in-out;
        &:hover  { background-color: rgba($primary60, 0.15); }
        &:active { background-color: rgba($primary60, 0.35); }
      }
      margin-bottom: 20px;

      @media (max-height: 600px) {
        margin-bottom: 10px;
      }
    }
    > .MyRatingHeaderBottom {
      flex: 0 0 auto;
      > h1 {
        @media (max-width: 560px) {
          font-size: 30px;
          line-height: 100%;
        }
      }
      & img {
        width: 40px;
        height: auto;
        margin-left: 10px;
        margin-bottom: -5px;
        -webkit-user-drag: none;

        @media (max-width: 560px) {
          width: 30px;
          margin-left: 5px;
        }
      }
    }
  }
}
