html {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
body.hidden {
  overflow: hidden;
}

html,
body,
head,
#root {
  padding: 0;
  margin: 0;
  min-height: 100vh;
}
#root {
  overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

* {
  -webkit-tap-highlight-color: transparent;
}

$fontFamilyMariupol: 'Mariupol', sans-serif;
$fontFamilyInter: 'Inter', sans-serif;
$fontFamilyRoboto: 'Roboto', sans-serif;

$white: #ffffff;

$darkest100: #0b1922;
$darkest80: #3c474e;
$darkest60: #6d757a;
$darkest40: #9da3a7;
$darkest20: #ced1d3;
$darkest10: #e7e8e9;
$darkest5: #f3f3f4;

$primary100: #f59d33;
$primary60: #f9c485;
$primary40: #fbd8ad;
$primary20: #fdebd6;
$primary15: #fdf0e0;
$primary10: #fef5eb;

$secondaryBlue100: #22dde9;
$secondaryPink100: #ff3950;
$secondaryPurple100: #9912f5;

@mixin h1 {
  font-family: $fontFamilyMariupol;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 109%;
}
@mixin h2 {
  font-family: $fontFamilyMariupol;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 116%;
}
@mixin h3 {
  font-family: $fontFamilyMariupol;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
}
@mixin p1 {
  font-family: $fontFamilyInter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
}
@mixin p2 {
  font-family: $fontFamilyInter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 123.5%;
}
@mixin p3 {
  font-family: $fontFamilyInter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 116%;
}
