.AppContent {
  flex: 1;

  display: block;
  width: 100%;
  box-sizing: border-box;

  min-height: 0;
  overflow: auto;

  > div {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;

    box-sizing: border-box;
  }
}
