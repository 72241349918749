@import '../../../../index.scss';

.wrap {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  gap: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  border-radius: 10px;
}

.title {
  font-family: $fontFamilyInter;
  font-size: 16px;
  font-weight: 500;
  color: $darkest100;
  line-height: 23.2px;
}
