@import '../../../../index';

.CitiesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
  margin-bottom: 32px;
  .CitiesTitle {
    @include h3;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .MoreButton {
      color: $primary100;
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;
    }
  }

  .CitiesList {
    gap: 8px;
    width: 100%;
    overflow-x: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }

    display: flex;
    align-items: center;
  }
}
