@import '../../../../index.scss';

.wrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background-color: #fffefc;
  border-radius: 16px;
}

.title {
  font-family: $fontFamilyInter;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.4px;
  color: $darkest100;
}
