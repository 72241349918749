@import '../../../../index.scss';

.title {
  font-family: $fontFamilyInter;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.title.disabled{
  color: $darkest60;
}
