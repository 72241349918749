@import '../../index.scss';
.page-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  padding: 24px 16px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.main-color {
  color: $primary100;
  font-weight: 700;
}

.link {
  color: $primary100;
}

.list {
  padding-left: 0;
  margin: 0;

  li {
    list-style: none;
  }

  li {
    position: relative;
    padding-left: 18px;
  }

  li::before {
    content: '•';
    position: absolute;
    left: 0;
    font-size: 24px;
    color: $primary100;
  }
}
