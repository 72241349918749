@import '../../../../index.scss';

.chip {
  background-color: white;
  font-family: $fontFamilyInter;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 7px 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  .title {
    color: $darkest100;
    margin-left: 8px;
    text-wrap: nowrap;
  }

  .icon {
    color: $darkest100;
  }
}

.chip[data-active='true'] {
  background-color: $primary100;
  .title {
    color: white;
  }

  .icon path {
    stroke: white;
  }
}
