@import '../../../../index.scss';

.offersResultWrap {
  background-color: $primary10;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .chipsWrap {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    background: #fdf0e0;
    padding: 20px;
    overflow-x: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .chipsWrap::-webkit-scrollbar {
    display: none;
  }

  .cardsWrap {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    padding: 0 25px;
  }

  .message {
    font-family: $fontFamilyInter;
    font-size: 16px;
    margin-top: 15px;
    text-align: center;
    color: #000;
  }
}

.virtWrap {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.virtWrap::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.virtWrap::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.seachValues {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  margin-top: 24px;
  @include h3;
}

.showMoreButton {
  flex: 0 0 auto;

  border: none;
  outline: none;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 24px;

  text-decoration: none;
  color: $primary100;
  font-family: $fontFamilyInter;
  font-weight: 700;
  font-size: 16px;
  text-align: center;

  cursor: pointer;

  margin-bottom: 10px;
}

.spacer {
  margin-bottom: 10px;
}

.goBackWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  padding: 0 25px;
  margin-top: 24px;
}

.goBackImage {
  width: 100%;
  max-width: 400px;
  border-radius: 24px;
}

.goBackDescription {
  font-family: $fontFamilyInter;
  font-weight: 500;
  font-size: 16px;
  line-height: 23.2px;
  color: $darkest60;
  text-align: center;
}
